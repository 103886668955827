import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getStockEntry as onGetStockEntry,
    addNewStockEntry as onAddNewStockEntry,
    deleteStockEntry as onDeleteStockEntry,
    updateStockEntry as onUpdateNewStockEntry,
} from "../store/stockentry/actions";
import { isEmpty, set } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import moment from 'moment';
import StoreItemSelect from "components/Common/StoreItemSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ContainerSelect from "components/Common/ContainerSelect";

const StockEntry = props => {

    //meta title
    document.title = "Mythreya - StockEntry";

    const dispatch = useDispatch();
    const [storeItem, setStoreItem] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedExpiryDate, setSelectedExpiryDate] = useState(null);
    const [selectStoreItem, setselectStoreItem] = useState(null);
    const [selectedStoreItem, setselectedStoreItem] = useState(null);
    const [selectContainer, setSelectContainer] = useState(null);
    const [selectedContainer, setSelectedContainer] = useState(null);
    const [selectedStoreItemMetric, setselectedStoreItemMetric] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const changeCategory = (val) => {
        validation.setFieldValue("store_item", val.value)
        setselectedStoreItem(val.value)
        setselectedStoreItemMetric(val.metric)
    }
    const changeContainer = (val) => {
        validation.setFieldValue("container", val.value)
        setSelectedContainer(val.value)
        setSelectContainer(val.value)
    }
    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            qty: (storeItem && storeItem.qty) || "",
            store_item: (storeItem && storeItem.store_item) || "",
            container: (storeItem && storeItem.container) || null,
            container_qty: (storeItem && storeItem.container_qty) || 0,
            expiry_date: (storeItem && storeItem.expiry_date) || "",
            price: (storeItem && storeItem.price) || 0,
            total_price: 0
        },
        validationSchema: Yup.object({
            qty: Yup.string().required("Please Enter Name"),
            store_item: Yup.string().required("Please Select Store Item"),
            container: Yup.string(),
            container_qty: Yup.string(),
            expiry_date: Yup.string(),
            price: Yup.string(),
            total_price: Yup.string()
        }),
        onSubmit: values => {
            
            if(isEdit){
                const updateStoreCategory = {
                    id: storeItem.id,
                    container: selectedContainer,
                    container_qty: values["container_qty"],
                    expiry_date: selectedExpiryDate,
                    price: values["price"],
                    total_price: (values["price"] != "" && values["container_qty"] != "")?(values["price"] * values["container_qty"]):0
                };
                console.log(updateStoreCategory)
                dispatch(onUpdateNewStockEntry(updateStoreCategory));
            }else{
                const newStoreItem = {
                    qty: values["qty"],
                    store_item: selectedStoreItem,
                    cdate: selectedDate,
                    container: selectedContainer,
                    container_qty: values["container_qty"],
                    expiry_date: selectedExpiryDate,
                    price: values["price"],
                    total_price: (values["price"] != "" && values["container_qty"] != "")?(values["price"] * values["container_qty"]):0
                };
                dispatch(onAddNewStockEntry(newStoreItem));
            }
            setStoreItem({})
            // save new user
            
            validation.resetForm();
            toggle();
        },
    });

    const selectStockEntryState = (state) => state.stockentry;
    const StockEntryProperties = createSelector(
        selectStockEntryState,
        (StockEntry) => ({
            stokeEntries: StockEntry.stockentry,
            loading: StockEntry.loading
        })
    );

    const {
        stokeEntries, loading
    } = useSelector(StockEntryProperties);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    const [storeItemList, setStoreItemList] = useState([]);
    const columns = useMemo(
        () => [
            // {
            //     Header: "Date",
            //     accessor: "cdate",
            //     filterable: true,
            //     Cell: cellProps => {
            //         return (
            //             <>
            //                 <h5 className="font-size-14 mb-1">
            //                     <Link className="text-dark" to="#">{moment(cellProps.row.original.cdate).format('MMM D, YYYY')}</Link>
            //                 </h5>
            //             </>
            //         )
            //     },
            // },
            {
                Header: "Store Item",
                accessor: "store_item",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.store_item?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Qty",
                accessor: "qty",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.qty} {cellProps.row.original.store_item?.store_metric?.name}</Link>
                            </h5>
                            <p className="text-muted mb-0">{cellProps.row.original.designation}</p>
                        </>
                    )
                },
            },
            {
                Header: "Container",
                accessor: "container",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.container_qty} {cellProps.row.original?.container}</Link>
                            </h5>

                        </>
                    )
                },
            },
            {
                Header: "Expiry Date",
                accessor: "expiry_date",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.expiry_date ? moment(cellProps.row.original.expiry_date).format('MMM D, YYYY') : "NA"}</Link>
                            </h5>

                        </>
                    )
                },
            },
            {
                Header: "Price",
                accessor: "price",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">Rs. {cellProps.row.original.price}</Link>
                            </h5>

                        </>
                    )
                },
            },
            {
                Header: "Total",
                accessor: "total_price",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.total_price?"Rs. "+cellProps.row.original.total_price?.toFixed(2):"NA"}</Link>
                            </h5>

                        </>
                    )
                },
            },
            // {
            //     Header: "Updated By",
            //     accessor: "createdBy",
            //     filterable: true,
            //     Cell: cellProps => {
            //         return (
            //             <>
            //                 <h5 className="font-size-14 mb-1">
            //                     <Link className="text-dark" to="#">{cellProps.row.original.createdBy?.name}</Link>
            //                 </h5>
            //             </>
            //         )
            //     },
            // },
            {
                Header: "#",
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                             <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleStoreItemClick(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    onClickDelete(userData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (stokeEntries && !stokeEntries.length) {
            const formattedDate = new Date().toISOString().split('T')[0];
            dispatch(onGetStockEntry(formattedDate));
            setIsEdit(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setStoreItem(stokeEntries);
        setIsEdit(false);
    }, [stokeEntries]);

    useEffect(() => {
        if (!isEmpty(stokeEntries) && !!isEdit) {
            setStoreItem(stokeEntries);
            setIsEdit(false);
        }
    }, [stokeEntries]);

    const toggle = () => {
        setModal(!modal);
    };


    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = storeItems => {
        setStoreItem(storeItems);
        setDeleteModal(true);
    };

    const handleDeleteStoreItem = () => {
        if (storeItem && storeItem.id) {
            dispatch(onDeleteStockEntry(storeItem.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };


    const handleStoreItemClicks = () => {
        setStoreItem({})
        setStoreItemList("");
        setIsEdit(false);
        setselectStoreItem(null)
        setselectedStoreItem(null)
        setSelectContainer(null)
        setSelectedContainer(null)
        setselectedStoreItemMetric(null)
        setSelectedExpiryDate(null)
        validation.resetForm();
        toggle();
    };

    const handleStoreItemClick = arg => {
        const storeItem_ = arg;
        console.log(storeItem_) 
        setselectedStoreItem(storeItem_.store_item._id)
        setselectStoreItem(storeItem_.store_item._id);
        setselectedStoreItemMetric(storeItem_.store_item?.store_metric?.name)

        setSelectContainer(storeItem_.container);
        setSelectedContainer(storeItem_.container)
        console.log(storeItem_.expiry_date)
        // const formattedDate = storeItem_.expiry_date.toISOString().split('T')[0];
        // setSelectedExpiryDate(formattedDate)
        const expiryDate = new Date(storeItem_.expiry_date);
        setSelectedExpiryDate(expiryDate);
        setStoreItem({
            id:storeItem_.id,
            qty: storeItem_.qty,
            store_item: storeItem_.store_item._id,
            container: storeItem_.container,
            container_qty: storeItem_.container_qty,
            expiry_date: storeItem_.expiry_date,
            price: storeItem_.price,
        });
        
        setIsEdit(true);

        toggle();
    };

    const handleDateChange = (date) => {
        setSelectedDate(date)
        const formattedDate = date.toISOString().split('T')[0];
        dispatch(onGetStockEntry(formattedDate));
    }

    const handleDateExpiryChange = (date) => {
        setSelectedExpiryDate(date)
    }

    const keyField = "id";
    return (
        loading ? <Spinners setLoading={setLoading} />
            :
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteStoreItem}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs breadcrumbItem="StockEntry" />
                        <Row>
                            {
                                isLoading ? <Spinners setLoading={setLoading} />
                                    :
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    isPagination={true}
                                                    columns={columns}
                                                    data={stokeEntries}
                                                    isGlobalFilter={true}
                                                    isShowingPageLength={true}
                                                    buttonText={"Add Stock"}
                                                    customDate={true}
                                                    isAddUserList={true}
                                                    // iscustomPageSizeOptions={true}
                                                    handleUserClick={handleStoreItemClicks}
                                                    selectedDate={selectedDate}
                                                    handleDateChange={handleDateChange}
                                                    customPageSize={50}
                                                    tableClass="table align-middle table-nowrap table-hover"
                                                    theadClass="table-light"
                                                    paginationDiv="col-sm-12 col-md-7"
                                                    pagination="pagination pagination-rounded justify-content-end mt-4"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                            }

                            <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? "Edit StoreItem" : "Add Stoke Entry"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            console.log(validation)
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col xs={12}>
                                                
                                                <div className="mb-3" >
                                                    <Label>Select Item</Label>
                                                    <StoreItemSelect readOnly={isEdit} isSubmitted={isSubmitted} error={validation.errors.store_item} defaultValue={selectStoreItem} OnSelect={changeCategory}></StoreItemSelect>
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Qty ({selectedStoreItemMetric && <span>{selectedStoreItemMetric}</span>})</Label>
                                                    <Input
                                                        name="qty"
                                                        type="text"
                                                        readOnly={isEdit}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.qty || ""}
                                                        invalid={
                                                            validation.touched.qty &&
                                                                validation.errors.qty
                                                                ? true
                                                                : false
                                                        }
                                                    />

                                                    {validation.touched.qty &&
                                                        validation.errors.qty ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.qty}
                                                        </FormFeedback>
                                                    ) : null}

                                                </div>
                                                <div className="mb-3 gap-2 d-flex">
                                                    <div className=" w-50">
                                                        <Label>Select Container</Label>
                                                        <ContainerSelect isSubmitted={isSubmitted} error={validation.errors.container} defaultValue={selectContainer} OnSelect={changeContainer}></ContainerSelect>
                                                    </div>
                                                    <div className="w-50">
                                                        <Label>No Of</Label>
                                                        <Input
                                                            name="container_qty"
                                                            type="text"

                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.container_qty || ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mb-3 gap-2 d-flex">
                                                    <div className=" w-50">
                                                        <Label className="form-label">Expiray Date</Label>
                                                        <DatePicker
                                                            selected={selectedExpiryDate}
                                                            openToDate={new Date()}
                                                            onChange={handleDateExpiryChange}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="w-100"
                                                            placeholderText="Select a date"
                                                        />
                                                    </div>
                                                    <div className=" w-50">
                                                        <Label className="form-label">Price</Label>
                                                        <Input
                                                            name="price"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.price || ""}
                                                            invalid={
                                                                validation.touched.price &&
                                                                    validation.errors.price
                                                                    ? true
                                                                    : false
                                                            }
                                                        />

                                                        {validation.touched.price &&
                                                            validation.errors.price ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.price}
                                                            </FormFeedback>
                                                        ) : null}

                                                    </div>
                                                </div>
                                                <div className=" w-50">
                                                    <Label className="form-label">Total Amount</Label>
                                                    <Input
                                                        name="total_price"
                                                        type="text"
                                                        readOnly="true"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.price * validation.values.container_qty || ""}
                                                        
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success save-user"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ModalBody>
                            </Modal>
                        </Row>
                    </Container>
                </div>
                <ToastContainer />
            </React.Fragment>
    );
};

export default withRouter(StockEntry);
