import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "../components/Common/withRouter";
import TableContainer from "../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    UncontrolledTooltip,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteModal from "../components/Common/DeleteModal";

import {
    getStockBalance,
} from "../store/stockbalance/actions";
import { isEmpty, set } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { toast, ToastContainer } from "react-toastify";
import moment from 'moment';
import StoreItemSelect from "components/Common/StoreItemSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HeadSelect from "components/Common/HeadSelect";
import AvailableSelect from "components/Common/AvailableSelect";

const StockReport = props => {

    //meta title
    document.title = "Mythreya - Stock Update";

    const dispatch = useDispatch();
    const [storeItem, setStoreItem] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectStoreItem, setselectStoreItem] = useState(null);
    const [selectedStoreItem, setselectedStoreItem] = useState(null);
    const [selectedStoreItemMetric, setselectedStoreItemMetric] = useState(null);
    const [selectStoreHead, setSelectStoreHead] = useState(null);
    const [selectedStoreHead, setSelectedStoreHead] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const changeCategory = (val) => {
        validation.setFieldValue("store_item", val.value)
        setselectedStoreItem(val)
        setselectedStoreItemMetric(val.metric)
    }
    const changeHead = (val) => {
        validation.setFieldValue("head", val.value)
        setSelectedStoreHead(val.value)
    }
    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            qty: (storeItem && storeItem.qty) || "",
            store_item: (storeItem && storeItem.store_item?.id) || "",
            head: (storeItem && storeItem.head?.id) || "",
        },
        validationSchema: Yup.object({
            qty: Yup.string().required("Please Enter Name"),
            store_item: Yup.string().required("Please Select Store Item"),
            head: Yup.string().required("Please Select Head"),
        }),
        onSubmit: values => {
            console.log(values.qty)
            console.log(selectedStoreItem.available)
            if(selectedStoreItem.available != 0 && values["qty"] <= selectedStoreItem.available){
                const newStoreItem = {
                    qty: values["qty"],
                    store_item:selectedStoreItem.value,
                    head:selectedStoreHead,
                    cdate:selectedDate
                };
                // save new user
                dispatch(onAddNewStockUpdate(newStoreItem));
                validation.resetForm();
                toggle();
            }else{
                toast.error("Available Stock is 0 or Quantity is greater than Available Stock")
            }
            
        },
    });

    const selectStockUpdateState = (state) => state.stockbalance;
    const StockUpdateProperties = createSelector(
        selectStockUpdateState,
        (StockUpdate) => ({
            stokeEntries: StockUpdate.stockbalance,
            loading: StockUpdate.loading
        })
    );

    const {
        stokeEntries, loading
    } = useSelector(StockUpdateProperties);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(loading)
    const [storeItemList, setStoreItemList] = useState([]);
    const columns = useMemo(
        () => [
            
            {
                Header: "Store Item",
                accessor: "store_item.name",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.store_item?.name}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "In Stock",
                accessor: "in_stock",
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.in_stock}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Out Stock",
                accessor: "out_stock",
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.out_stock}</Link>
                            </h5>
                        </>
                    )
                },
            },
            {
                Header: "Available Stock",
                Cell: cellProps => {
                    return (
                        <>
                            <h5 className="font-size-14 mb-1">
                                <Link className="text-dark" to="#">{cellProps.row.original.in_stock-cellProps.row.original.out_stock}</Link>
                            </h5>
                        </>
                    )
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (stokeEntries && !stokeEntries.length) {
            const formattedDate = new Date().toISOString().split('T')[0];
            dispatch(getStockBalance());
            setIsEdit(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setStoreItem(stokeEntries);
        setIsEdit(false);
    }, [stokeEntries]);

    useEffect(() => {
        if (!isEmpty(stokeEntries) && !!isEdit) {
            setStoreItem(stokeEntries);
            setIsEdit(false);
        }
    }, [stokeEntries]);

    const toggle = () => {
        setModal(!modal);
    };
    

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = storeItems => {
        setStoreItem(storeItems);
        setDeleteModal(true);
    };

    const handleDeleteStoreItem = () => {
        if (storeItem && storeItem.id) {
            dispatch(getStockBalance(storeItem.id));
        }
        onPaginationPageChange(1);
        setDeleteModal(false);
    };

    const handleStoreItemClicks = () => {
        setStoreItem({})
        setStoreItemList("");
        setIsEdit(false);
        setselectStoreItem(null)
        setselectedStoreItem(null)
        setselectedStoreItemMetric(null)
        setSelectStoreHead(null)
        validation.resetForm();
        toggle();
    };

    const handleDateChange = (date) => {
        setSelectedDate(date)
        const formattedDate = date.toISOString().split('T')[0];
        dispatch(onGetStockUpdate(formattedDate));
    }
    
    const keyField = "id";
    
    return (
        loading ? <Spinners setLoading={setLoading} />
            :
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteStoreItem}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="Stock Report" />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                isPagination={true}
                                                columns={columns}
                                                data={stokeEntries}
                                                isGlobalFilter={true}
                                                isShowingPageLength={true}
                                                c={"Use Stock"}
                                                customDate={false}
                                                isAddUserList={false}
                                                // iscustomPageSizeOptions={true}
                                                handleUserClick={handleStoreItemClicks}
                                                selectedDate={selectedDate}
                                                handleDateChange={handleDateChange}
                                                customPageSize={50}
                                                tableClass="table align-middle table-nowrap table-hover"
                                                theadClass="table-light"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination pagination-rounded justify-content-end mt-4"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }

                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle} tag="h4">
                                {!!isEdit ? "Edit StoreItem" : "Stoke Update"}
                            </ModalHeader>
                            <ModalBody>
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <div className="mb-3">
                                                <Label>Select Store</Label>
                                                <HeadSelect isSubmitted={isSubmitted} error={validation.errors.head} defaultValue={selectStoreHead} OnSelect={changeHead}></HeadSelect>
                                            </div>
                                            <div className="mb-3">
                                                <Label>Available Store Item</Label>
                                                <AvailableSelect isSubmitted={isSubmitted} error={validation.errors.store_item} defaultValue={selectStoreItem} OnSelect={changeCategory}></AvailableSelect>
                                                <Label className="my-2">Available : {selectedStoreItem && selectedStoreItem.available}</Label>
                                                {/* <StoreItemSelect isSubmitted={isSubmitted} error={validation.errors.store_item} defaultValue={selectStoreItem} OnSelect={changeCategory}></StoreItemSelect> */}
                                            </div>
                                            
                                            <div className="mb-3">
                                                <Label className="form-label">Qty({selectedStoreItemMetric && <span>{selectedStoreItemMetric}</span>})</Label>
                                                <Input
                                                    name="qty"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.qty || ""}
                                                    invalid={
                                                        validation.touched.qty &&
                                                            validation.errors.qty
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                
                                                {validation.touched.qty &&
                                                    validation.errors.qty ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.qty}
                                                    </FormFeedback>
                                                ) : null}
                                                
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRouter(StockReport);
