/* USERS */
export const GET_STOCK_ENTRYS = "GET_STOCK_ENTRYS"
export const GET_STOCK_ENTRYS_SUCCESS = "GET_STOCK_ENTRYS_SUCCESS"
export const GET_STOCK_ENTRYS_FAIL = "GET_STOCK_ENTRYS_FAIL"

/* CATEGORS PROFILE */
export const GET_STOCK_ENTRY = "GET_STOCK_ENTRY"
export const GET_STOCK_ENTRY_SUCCESS = "GET_STOCK_ENTRY_SUCCESS"
export const GET_STOCK_ENTRY_FAIL = "GET_STOCK_ENTRY_FAIL"

export const UPDATE_STOCK_ENTRY = "UPDATE_STOCK_ENTRY"
export const UPDATE_STOCK_ENTRY_SUCCESS = "UPDATE_STOCK_ENTRY_SUCCESS"
export const UPDATE_STOCK_ENTRY_FAIL = "UPDATE_STOCK_ENTRY_FAIL"

/**
 * add user
 */
export const ADD_NEW_STOCK_ENTRY = "ADD_NEW_STOCK_ENTRY"
export const ADD_STOCK_ENTRY_SUCCESS = "ADD_STOCK_ENTRY_SUCCESS"
export const ADD_STOCK_ENTRY_FAIL = "ADD_STOCK_ENTRY_FAIL"

/**
 * Delete user
 */
export const DELETE_STOCK_ENTRY = "DELETE_STOCK_ENTRY"
export const DELETE_STOCK_ENTRY_SUCCESS = "DELETE_STOCK_ENTRY_SUCCESS"
export const DELETE_STOCK_ENTRY_FAIL = "DELETE_STOCK_ENTRY_FAIL"
